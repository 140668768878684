export default {
	name: 'warrantshoutout',
	created() {
		window.shoutTabClick = this.shoutTabClick
	},
	mounted() {
		this.getshoutoutDate()
	},
	data() {
		return {
			dataArr: [],
			limit: 10,
			limitText: this.$t('m.showAll'),
		}
	},
	computed: {
		showAll: {
			get() {
				return this.$t('m.showAll')
			},
		},
		showLatest: {
			get() {
				return this.$t('m.showLatest')
			},
		},
	},
	watch: {
		showAll: {
			handler: function(newVal, oldVal) {
				this.limitText = newVal
			},
			deep: true,
		},
		showLatest: {
			handler: function(newVal, oldVal) {
				this.limitText = newVal
			},
			deep: true,
		},
	},
	methods: {
		ShowAll() {
			if (this.limitText === this.$t('m.showAll')) {
				this.limit = ''
				this.limitText = this.$t('m.showLatest')
			} else if (this.limitText === this.$t('m.showLatest')) {
				this.limit = 10
				this.limitText = this.$t('m.showAll')
			}
			this.getshoutoutDate()
		},
		getshoutoutDate() {
			$.ajax({
				type: 'post',
				url: this.$js.webservice + 'GetDeployedAnnouncement',
				data: {
					token: 'webkey',
					limit: this.limit,
					lang: window.sessionStorage.getItem('lang'),
				},
				dataType: 'json',
				success: (data) => {
					if (data.status === 'SUCCESS' && data.text.length > 0) {
						this.dataArr = data.text
						this.assignmentShoutout(this.dataArr)
						this.shoutTabClick(0)
					}
				},
				error: function(error) {
					console.log(error)
				},
			})
		},
		assignmentShoutout(data) {
			var html = ''
			$.each(data, (index, datas) => {
				var date = this.$js.formatDate(datas.created_datetime)
				html +=
					"<div class='row shout-tab cur-pointer' onClick='shoutTabClick(" +
					index +
					',' +
					true +
					")'>"
				html += '<div class="col-4 pr-0">'
				html +=
					'<div class="font-size14 font-weight-bold pl-2 pb-1"><span>' +
					date +
					'</span></div>'
				html += '</div>'
				html += '<div class="col pr-2">'
				html +=
					'<div class="font-size14 font-weight-bold shout-title Shoutoutwarrants_title"><span>' +
					datas.title +
					'</span></div>'
				html +=
					'<div class="Shoutoutwarrants_content font_size14"><span>' +
					datas.overview +
					'</span></div>'
				html += '</div>'
				html += '</div>'
			})
			$('.shout-left-bar').find('.textBox').empty()
			$('.shout-left-bar').find('.textBox').append(html)
		},
		shoutTabClick(index, ifv) {
			this.$js.SetAnchor($('.shout-right-bar').offset().top)
			if (localStorage.getItem('ShoutoutwarrantsLi')) {
				index = localStorage.getItem('ShoutoutwarrantsLi')
				localStorage.removeItem('ShoutoutwarrantsLi')
			}
			//赋值
			$('#rightText').html(this.dataArr[index].title)
			$('#rightContent').html(this.dataArr[index].content)
			$('.shout-tab')
				.eq(index)
				.addClass('shouttab_clickbg')
				.siblings()
				.removeClass('shouttab_clickbg')
			if (ifv) {
				$('#Shoutoutwarrants')
					.find('.form-control')
					.val('')
				$('.shout-left-bar')
					.find('.shout-tab')
					.each((i, d) => {
						$(d).show()
					})
			}
		},
		//搜寻
		search: function(e) {
			$('.shout-left-bar')
				.find('.shout-tab')
				.each((i, l) => {
					$(l).hide()
					if (e.target.value) {
						if (
							$(l)
								.text()
								.replace(/\s+/g, '')
								.toLowerCase()
								.indexOf(e.target.value.toLowerCase()) >= 0 ||
							e.target.value.toLowerCase().indexOf(
								$(l)
									.text()
									.replace(/\s+/g, '')
									.toLowerCase()
							) >= 0
						) {
							$(l).show()
						}
					} else {
						$(l).show()
					}
				})
		},
	},
}
