import { render, staticRenderFns } from "./WarrantShout-Out.vue?vue&type=template&id=06b38075&scoped=true&"
import script from "./WarrantShout-Out.js?vue&type=script&lang=js&"
export * from "./WarrantShout-Out.js?vue&type=script&lang=js&"
import style0 from "./WarrantShout-Out.vue?vue&type=style&index=0&id=06b38075&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b38075",
  null
  
)

export default component.exports